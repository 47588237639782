import { Fragment, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useRadix } from '~/rdt/radixProvider';
import { classNames } from '~/lib/util';

export default function TransactionNotification() {
   const [show, setShow] = useState(false);
   const [transactionId, setTransactionId] = useState('');
	const { transactionError } = useRadix();

   useEffect(() => {
      console.log('🚨', transactionError);

      if (transactionError) {
         setShow(true);
      }
   }, [transactionError]);

   return (
		<>
			{/* Global notification live region, render this permanently at the end of the document */}
			<div aria-live="assertive" className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6">
				<div className="flex w-full flex-col items-center space-y-4 sm:items-end">
					{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
					<Transition
						show={show}
						as={Fragment}
						enter="transform ease-out duration-300 transition"
						enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
						enterTo="translate-y-0 opacity-100 sm:translate-x-0"
						leave="transition ease-in duration-100"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div
							className={classNames(
								transactionError ? "border-red-500" : "",
								"pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg border bg-white text-black shadow-lg ring-1 ring-black ring-opacity-5",
							)}
						>
							<div className="p-4">
								<div className="flex items-start">
									<div className="flex-shrink-0">
										{transactionError ? (
											<ExclamationTriangleIcon className="h-6 w-6 text-red-300" aria-hidden="true" />
										) : (
											<CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
										)}
									</div>
									{transactionError == "rejectedByUser" ? (
										<div className="ml-3 w-0 flex-1 pt-0.5">
											<p className="text-sm font-medium">You canceled the transaction</p>
										</div>
									) : (
										<div className="ml-3 w-0 flex-1 pt-0.5">
											<p className="text-sm font-medium">{transactionError ? "An error occured" : "Transaction sent"}</p>
                                    <p className="mt-1 text-sm">{transactionError}</p>
										</div>
									)}
									<div className="ml-4 flex flex-shrink-0">
										<button
											type="button"
											className="inline-flex text-black focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2"
											onClick={() => {
												setShow(false);
											}}
										>
											<span className="sr-only">Close</span>
											<XMarkIcon className="h-5 w-5" aria-hidden="true" />
										</button>
									</div>
								</div>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		</>
	);
}
